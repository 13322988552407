

export function mobileNav() {
  if (!document.querySelector('.home')) {
    const hamburger = document.getElementById('hamburger')
    const mobileNav = document.getElementById('mobile-nav')
    const desktopNav = document.getElementById('desktop-nav')
    const body = document.querySelector('body')

    function viewportCheck() {
      if (window.innerWidth < 900) {
        hamburger.classList.add('show')
        desktopNav.classList.add('hide')
      } else {
        hamburger.classList.remove('show')
        desktopNav.classList.remove('hide')
      }
    }

    function hamburgerTranform() {
      const evenChildren = document.querySelectorAll('#hamburger :nth-child(even)')
      evenChildren.forEach(child => {
        child.classList.toggle('hide')
      })
    }

    viewportCheck()

    addEventListener('resize', () => {
      viewportCheck()
    })

    hamburger.addEventListener('click', () => {
      mobileNav.classList.toggle('active')
      hamburger.classList.toggle('active')
      body.classList.toggle('no-scroll')
      hamburgerTranform()
    })

    
  }
}
